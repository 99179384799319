.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left: -15px;
    
}



.logo-svg {
    width: 100%;
    height: auto;
    max-width: 150px; /* Adjust this value as needed */
}


@media screen and (max-width: 768px) {
    .footer .logo-container {
        margin-top: 2rem;
    }
}