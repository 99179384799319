.privacy-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0rem 25%;
    max-width: 1500px;
}

.privacy-container ul{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.privacy-container a {
    color: var(--primary-color);
    text-decoration: underline;
    
}

@media screen and (max-width: 768px) {
    .privacy-container {
        margin: 0rem 5%;
        max-width: 100%;
    }
    .privacy-container ul {
        margin: 5px;
    }
    .privacy-container h1 {
        align-self: center;
        font-size: 40px;
    }
}