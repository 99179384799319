.about-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0rem 25%;
    max-width: 1500px;
}


@media screen and (max-width: 768px) {
    .about-container {
        margin: 0rem 5%;
    }
    .about-container ul {
        margin: 10px;
    }

    .about-container h1 {
        margin-top: 2rem;
        align-self: center;
        font-size: 48px;
    }
}
