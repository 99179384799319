
/* Secondary button */
.btn-secondary {
    background-color: transparent;
    color: var(--primary-color-dark);
    border: 2px solid var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    border-radius: 12px;
}

.btn-secondary:hover {
    /* background-color: rgba(243, 124, 54, 0.1); */
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(243, 124, 54, 0.1);
}

.btn-secondary:active {
    transform: translateY(0);
}

