.nav-container {
    width: 100%;
    max-width: 1200px;
    position: relative;
    margin: 0 auto 40px;
    display: flex;
    justify-content: center;
}

.menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 60px;
    width: 100%;
    max-width: 800px;
}

.menu h2 {
    color: #555;
    font-size: 1.3rem;
    cursor: pointer;
    padding: 10px 20px;
    position: relative;
    text-align: center;
    transition: color 0.3s ease;
    font-weight: 500;
}

.menu h2:hover {
    color: var(--primary-color);
}

.menu h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.menu h2.active {
    color: var(--primary-color);
}

.menu h2.active::after {
    background-color: var(--primary-color);
}

.hamburger2 {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
}

.hamburger2 span {
    width: 100%;
    height: 3px;
    background-color: var(--primary-color);
    border-radius: 3px;
    transition: all 0.3s ease;
}

@media screen and (max-width: 768px) {
    .nav-container {
        width: 100%;
        margin-bottom: 30px;
    }

    .hamburger2 {
        display: flex;
    }

    .menu {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 20px;
        right: 0;
        background-color: white;
        padding: 20px;
        box-shadow: 0 4px 15px rgba(0,0,0,0.1);
        width: 250px;
        border-radius: 8px;
        z-index: 1000;
        gap: 0;
    }

    .menu.open {
        display: flex;
    }

    .menu h2 {
        margin: 0;
        font-size: 1.1rem;
        padding: 15px 10px;
        border-bottom: 1px solid rgba(0,0,0,0.05);
        text-align: left;
        width: 100%;
    }

    .menu h2:last-child {
        border-bottom: none;
    }

    .menu h2:hover {
        color: var(--primary-color);
        background-color: rgba(0,0,0,0.02);
    }

    .menu h2::after {
        display: none;
    }

    .hamburger2.open span:first-child {
        transform: translateY(9px) rotate(45deg);
    }

    .hamburger2.open span:nth-child(2) {
        opacity: 0;
    }

    .hamburger2.open span:last-child {
        transform: translateY(-9px) rotate(-45deg);
    }
}

.profile {
    display: flex;
    justify-content: center;
    margin-top: 0;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: calc(100vh - 120px);
}

.cvContainer {
    display: none;
}

.actions {
    display: none;
}

.cv-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 35px;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    margin: 40px auto;
}

.cv-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    width: 220px;
    transition: transform 0.3s ease;
}

.cv-item:hover {
    transform: translateY(-5px);
}

.image-container {
    position: relative;
    width: 100%;
    height: 300px;
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
}

.image-container:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease;
}

.image-container:hover img {
    transform: scale(1.05);
}

.cv-preview {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.actions-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 8px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.03), transparent);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.image-container:hover .actions-container {
    opacity: 1;
}

.actions-container button {
    background-color: white;
    border: none;
    border-radius: 3px;
    padding: 4px 8px;
    font-size: 11px;
    font-weight: 500;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
}

.actions-container button:hover {
    background-color: var(--primary-color);
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.cv-name {
    width: 100%;
    text-align: center;
    font-weight: 600;
    margin: 8px 0 5px;
    color: #333;
    font-size: 1.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cv-date {
    font-size: 0.85rem;
    color: #777;
    margin-bottom: 8px;
}

.cv-actions {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 10px;
}

.cv-actions button {
    background: none;
    border: none;
    color: #666;
    font-size: 0.85rem;
    padding: 5px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: all 0.2s ease;
    border-radius: 4px;
}

.cv-actions button:hover {
    color: var(--primary-color);
    background-color: rgba(255, 136, 0, 0.05);
}

/* Loading state for download button */
.downloading {
    opacity: 0.8;
    pointer-events: none;
    position: relative;
}

.downloading:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    margin: -7px 0 0 -7px;
    border: 2px solid rgba(0,0,0,0.2);
    border-top-color: var(--primary-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.actions-container button.downloading {
    background-color: #f0f0f0;
    color: #888;
}

.actions-container button:disabled,
.cv-actions button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Add some responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .cv-grid {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 20px;
    }
    
    .cv-item {
        width: 160px;
    }
    
    .image-container {
        height: 220px;
    }
    
    .actions-container {
        padding: 3px 6px;
    }
    
    .actions-container button {
        padding: 3px 6px;
        font-size: 10px;
        gap: 3px;
    }
    
    .button-icon {
        width: 10px;
        height: 10px;
    }
    
    .cv-actions {
        flex-wrap: wrap;
        gap: 8px;
        justify-content: center;
    }
    
    .cv-actions button {
        font-size: 0.75rem;
    }
    
    .cv-name {
        font-size: 0.9rem;
    }
}

.cv-action-btn {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
    flex: 1 0 auto;
    text-align: center;
    min-width: 40px;
}

.cv-action-btn:hover {
    background-color: #e9e9e9;
}

.cv-action-btn.edit {
    background-color: var(--primary-color-light);
    color: white;
}

.cv-action-btn.edit:hover {
    background-color: var(--primary-color);
}

.cv-action-btn.delete {
    background-color: #ffeeee;
    color: #e74c3c;
    border-color: #ffd5d5;
}

.cv-action-btn.delete:hover {
    background-color: #ffd5d5;
}

.cv-action-btn.copy {
    background-color: #eef5ff;
    color: #3498db;
    border-color: #d5e6ff;
}

.cv-action-btn.copy:hover {
    background-color: #d5e6ff;
}

.cv-action-btn.download {
    background-color: #eefff5;
    color: #27ae60;
    border-color: #d5ffea;
}

.cv-action-btn.download:hover {
    background-color: #d5ffea;
}

.cv-action-btn.download.downloading {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
    border-color: #ddd;
    position: relative;
}

.cv-action-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.no-cvs-message {
    text-align: center;
    margin: 40px auto;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.05);
    max-width: 500px;
}

.no-cvs-message p {
    margin-bottom: 20px;
    color: #555;
    font-size: 1.1rem;
    line-height: 1.5;
}

.profileContainer {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.profileContainer h1 {
    text-align: center;
    margin-bottom: 40px;
    color: var(--primary-color);
    font-size: 2rem;
    font-weight: 600;
}

.message {
    text-align: center;
    margin: 15px auto 25px;
    padding: 12px 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    font-size: 0.95rem;
    color: #333;
    max-width: 600px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    transition: opacity 0.3s ease;
}

.message:empty {
    display: none;
}

.actionContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.newCVButton {
    margin: 40px 0;
    text-align: center;
    display: flex;
    justify-content: center;
}

.newCVButton button {
    padding: 14px 40px;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 8px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
    box-shadow: 0 4px 12px rgba(255, 136, 0, 0.2);
}

.newCVButton button:hover {
    background-color: var(--primary-color-dark);
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(255, 136, 0, 0.25);
}

@media screen and (max-width: 768px) {
    .profile {
        margin-top: 20px;
        padding: 10px;
    }
    
    .profileContainer h1 {
        font-size: 1.7rem;
        margin-bottom: 30px;
    }
    
    .cv-grid {
        gap: 20px;
        width: 95%;
    }
    
    .no-cvs-message {
        padding: 30px 20px;
        margin: 40px auto;
    }
    
    .no-cvs-message p {
        font-size: 1.1rem;
    }
    
    .newCVButton button {
        padding: 12px 30px;
        font-size: 1rem;
    }
}

.createUser {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.createUser h1 {
  margin-bottom: 30px;
  text-align: center;
  color: var(--primary-color);
  font-size: 2rem;
  font-weight: 600;
}

.profile-edit-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.input-row {
  display: flex;
  gap: 20px;
  /* margin-bottom: 15px; */
}

.input-row > div {
  flex: 1;
  width: 100%;
}

.input-row input {
  width: 100%;
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  font-size: 1rem;
  transition: border 0.2s ease;
}

.input-row input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 136, 0, 0.1);
}

.input-row input::placeholder {
  color: #aaa;
}

.account-actions {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 20px;
}

.primary-actions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.primary-actions button {
  padding: 12px 30px;
  font-size: 1rem;
  border-radius: 6px;
  font-weight: 500;
  text-transform: lowercase;
}

.danger-zone {
  margin-top: 40px;
  padding: 30px;
  background-color: #fff8f8;
  border-radius: 10px;
  position: relative;
  border: 1px solid rgba(231, 76, 60, 0.15);
}

.danger-divider {
  height: 2px;
  background-color: #e74c3c;
  border: none;
  margin: 0 0 20px 0;
}

.danger-zone h3 {
  color: #e74c3c;
  margin-bottom: 15px;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
}

.danger-description {
  color: #555;
  margin-bottom: 20px;
  font-size: 0.95rem;
  line-height: 1.6;
}

.delete-confirmation {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 20px;
}

.delete-confirmation > div:first-child {
  flex: 1;
}

.delete-confirmation input {
  width: 100%;
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid #e7776d;
  background-color: rgba(231, 76, 60, 0.05);
  font-size: 1rem;
}

.delete-confirmation input:focus {
  border-color: #e74c3c;
  outline: none;
  box-shadow: 0 0 0 2px rgba(231, 76, 60, 0.1);
}

.delete-confirmation button {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 25px;
  font-weight: 500;
  transition: background-color 0.2s ease;
  cursor: pointer;
  font-size: 0.9rem;
}

.delete-confirmation button:hover {
  background-color: #d62c1a;
}

@media screen and (max-width: 768px) {
  .createUser {
    padding: 20px;
    border-radius: 8px;
  }

  .input-row {
    flex-direction: column;
    gap: 15px;
  }
  
  .delete-confirmation {
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }
  
  .delete-confirmation > div:first-child {
    width: 100%;
  }
  
  .danger-zone {
    padding: 20px;
  }
}

.subscription-wrapper {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 30px;
}

.no-subscription-message {
  text-align: center;
  margin: 40px auto;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
  max-width: 600px;
}

.no-subscription-message p {
  margin-bottom: 30px;
  color: #555;
  font-size: 1.2rem;
  line-height: 1.6;
}

.subscription-list {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.subscription-item {
  background-color: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.subscription-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
}

.subscription-header h3 {
  font-size: 1.4rem;
  color: var(--primary-color);
  margin: 0;
  font-weight: 600;
}

.subscription-status {
  padding: 8px 16px;
  border-radius: 30px;
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.subscription-status.active {
  background-color: #e8f7ee;
  color: #27ae60;
}

.subscription-status.inactive {
  background-color: #feeeed;
  color: #e74c3c;
}

.subscription-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 25px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

.detail-row {
  display: flex;
  align-items: center;
}

.detail-label {
  flex: 0 0 150px;
  font-weight: 600;
  color: #555;
}

.detail-value {
  color: #333;
  word-break: break-all;
  font-family: monospace;
  font-size: 0.95rem;
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
}

.subscription-actions {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}

.subscription-actions button {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 25px;
  font-weight: 500;
  transition: background-color 0.2s ease;
  cursor: pointer;
  font-size: 0.95rem;
}

.subscription-actions button:hover {
  background-color: #d62c1a;
}

.subscription-message {
  margin-top: 20px;
  padding: 15px;
  background-color: #feeeed;
  color: #e74c3c;
  border-radius: 8px;
  font-size: 0.95rem;
  text-align: center;
  border: 1px solid rgba(231, 76, 60, 0.2);
}

@media screen and (max-width: 768px) {
  .subscription-wrapper {
    padding: 15px;
  }
  
  .subscription-item {
    padding: 20px;
    border-radius: 8px;
  }
  
  .subscription-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
  
  .detail-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  
  .detail-label {
    flex: 0 0 auto;
    margin-bottom: 3px;
  }
  
  .subscription-details {
    padding: 15px;
  }
  
  .detail-value {
    width: 100%;
    overflow-x: auto;
  }
  
  .no-subscription-message {
    padding: 30px 20px;
    margin: 30px auto;
  }
  
  .no-subscription-message p {
    font-size: 1.1rem;
  }
}

.button-icon {
  width: 12px;
  height: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  position: relative;
}

.edit-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z'%3E%3C/path%3E%3C/svg%3E");
}

.download-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'%3E%3C/path%3E%3Cpolyline points='7 10 12 15 17 10'%3E%3C/polyline%3E%3Cline x1='12' y1='15' x2='12' y2='3'%3E%3C/line%3E%3C/svg%3E");
}

.copy-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='9' y='9' width='13' height='13' rx='2' ry='2'%3E%3C/rect%3E%3Cpath d='M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1'%3E%3C/path%3E%3C/svg%3E");
}

.delete-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='3 6 5 6 21 6'%3E%3C/polyline%3E%3Cpath d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'%3E%3C/path%3E%3Cline x1='10' y1='11' x2='10' y2='17'%3E%3C/line%3E%3Cline x1='14' y1='11' x2='14' y2='17'%3E%3C/line%3E%3C/svg%3E");
}

.actions-container button:hover .edit-icon,
.actions-container button:hover .download-icon,
.cv-actions button:hover .copy-icon,
.cv-actions button:hover .delete-icon {
  filter: brightness(0) invert(1);
}