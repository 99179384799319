/* Base button styles */
.btn {
    padding: 16px 80px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
    border: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Primary button */
.btn-primary {
    background-color: var(--primary-color);
    color: var(--text-black);
    border: none;
    max-width: 150px;
  
    /* margin-top: 40px; */
    /* padding: 12px 80px; */

}

.btn-primary:hover {
    background-color: var(--primary-color-dark);
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(243, 124, 54, 0.2);

}

.btn-primary:active {
    transform: translateY(0);
}

a {
    text-decoration: none;
}


@media (max-width: 768px) {
    .btn-primary {
        margin-top: 80px;
        text-align: center;
    }
}