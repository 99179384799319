.agreements-container {
  max-width: 900px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--text-white);
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.agreements-container h1 {
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2.5rem;
}

.agreements-list {
  list-style-type: none;
  padding: 0;
}

.agreement-item {
  background-color: var(--text-white);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
  border: 1px solid var(--primary-color-light);
}

.agreement-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.agreement-item h2 {
  color: var(--primary-color-dark);
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  border-bottom: 2px solid var(--primary-color-light);
  padding-bottom: 0.5rem;
}

.agreement-item h3 {
  color: var(--primary-color);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.agreement-item p {
  color: var(--text-black);
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.agreement-details {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: var(--shade-gray);
  margin-bottom: 0.5rem;
}

.agreement-details span {
  background-color: var(--primary-color-opacity);
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-weight: bold;
}

.agreement-details span[data-status="active"] {
  background-color: rgba(0, 128, 0, 0.2);
  color: #006400;
}

.agreement-details span[data-status="stopped"] {
  background-color: rgba(220, 20, 60, 0.2);
  color: #8B0000;
}

.agreement-pricing, .agreement-interval, .agreement-campaign {
  background-color: var(--secondary-color);
  border-radius: 12px;
  padding: 1rem;
  margin-top: 1rem;
}

.agreement-campaign {
  border-left: 4px solid var(--primary-color);
}

@media screen and (max-width: 768px) {
  .agreements-container {
    padding: 1rem;
    margin: 1rem;
  }

  .agreement-item {
    padding: 1rem;
  }

  .agreement-details {
    flex-direction: column;
    gap: 0.5rem;
  }

  .agreement-details span {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
}
