#magic {
    z-index: 0 !important;
}


document, page {
    width: 595px !important;
    height: 841px !important;
}

document {
    scale: 0.6;
}

link {
    display: inline;
}

.pdfViewer {
 scale: 0.6;
}

canvas {
    scale: 1.3;
}

@media screen and (max-width: 660px) {
   document, page {
    /* width: 100% !important; */
    /* height: 100% !important; */
   }

   canvas {
    scale: 1;
  }


}

@media screen and (min-width: 2000px) {
    canvas {
        scale: 2;
    }
}