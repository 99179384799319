.sales-terms-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0rem 25%;
  max-width: 1500px;
}

.sales-terms-container ul{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.sales-terms-container a {
    color: var(--primary-color);
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
    .sales-terms-container {
        margin: 0rem 5%;
    }
    .sales-terms-container ul {
        margin: 10px;
    }

    .sales-terms-container h1 {
        align-self: center;
        font-size: 48px;
    }
}

