.create-cv-template {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.create-cv-template h1 {
    text-align: center;
    margin-bottom: 20px;
}

.create-cv-template form > div {
    margin-bottom: 15px;
}

.create-cv-template label {
    display: block;
    margin-bottom: 5px;
}

.create-cv-template input[type="text"],
.create-cv-template select,
.create-cv-template textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--text-white);
    border-radius: 4px;
}

.create-cv-template input[type="color"] {
    margin-right: 10px;
}

.colorSection {
display: flex;
}

.create-cv-template button {
    background-color: var(--primary-color);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.create-cv-template button:hover {
    background-color: var(--primary-color-dark);
}

.create-cv-template button[type="button"] {
    background-color: var(--primary-color);
    margin-left: 10px;
}

.create-cv-template button[type="button"]:hover {
    background-color: var(--primary-color-dark);
}