/* Text button */
.btn-text {
    background-color: transparent;
    color: var(--primary-color-dark);
    border: none;
    padding: 12px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-text:hover {
    background-color: var(--primary-color-opacity);
    border-radius: 12px;
}

/* Optional: Disabled state for all buttons */
.btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}