.footer {
  /* border-top: 3px solid rgba(46, 113, 133, 0.6); */
  display: flex;
  position: relative;
  flex-direction: row;
  bottom: 0;
  width: 100%;
  justify-content: space-between;
  padding: 50px 0px;
  justify-content: center;
  background-image: url("../../public/img/backgroundSplash.png");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #cbc2c7;
  z-index: 5;
}

.footer-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 50%; */
}

.footer-container {
  margin: 0px 60px;
  padding-top: 20px;
}

.footer-mobile {
  display: none;
}

.footer div a {
  color: var(--text-black);
  text-decoration: none;
  /* padding: 5px 0px; */
  /* margin: 10px 0px; */
  display: flex;
}

.footer div {
  padding: 5px 0px;
  /* text-decoration: underline; */
}

.logout-button {
  color: var(--text-black);
  border: none;
  padding: 0px;
  text-decoration: underline;
  /* font-family: 'Lato', sans-serif; */

  font-size: 1rem;
  /* border-bottom: 1px solid var(--text-black); */
  /* border-radius: 5px; */
  cursor: pointer;
}

.logout-button:hover {
  /* background-color: var(--primary-color-dark); */
  padding: 0px 2px;
}
.footer-title {
  font-weight: 900;
  font-size: 1.3rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 660px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-top: 0px solid var(--primary-color);
    /* background-image: url("../../public/img/backgroundSplashMobile.png"); */
    background: rgb(204, 161, 145);
    background: linear-gradient(
      176deg,
      rgba(208, 165, 148, 1) 0%,
      rgba(208, 165, 148, 1) 10%,
      rgba(208, 165, 148, 1) 20%,
      rgba(212, 178, 187, 1) 42%,
      rgba(169, 160, 180, 1) 80%,
      rgba(156, 154, 178, 1) 100%,
      rgba(156, 154, 178, 1) 100%,
      rgba(208, 165, 148, 1) 100%,
      rgba(208, 165, 148, 1) 100%
    );
  }
  .footer-container {
    display: flex;
    flex-direction: column;
    margin: 0px 15px;
    /* background-color: red; */
  }

  /* .footer div {
    margin: 0px;
} */

  .footer-desktop {
    display: none;
  }

  .footer-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem 0rem;
    gap: 0.2rem;
  }

  .footer-mobile img {
    padding: 2rem 0px;
    scale: 1.5;
  }

  .accordion-item-footer {
    width: 80%;
    border-bottom: 2px solid var(--text-black);
  }

  .accordion-content {
    padding-left: 20px;
    /* padding-top: 10px; */
  }

  .accordion-content-inner a,
  .accordion-content-inner div,
  .accordion-content-inner button {
    padding: 4px 0px 4px 20px;
  }

  .accordion-content-inner button:hover {
    padding: 4px 0px 4px 20px;
  }

  .footer-container div {
    margin: 0px;
    padding: 5px 0px;
  }

  .footer img {
    width: 80px;
  }

  .accordion-content.active {
    max-height: 300px;
    transition: max-height 0.5s ease-in, padding 0.5s ease-in;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
