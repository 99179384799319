.application-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  /* font-family: "Lato", sans-serif; */
  color: var(--text-black);
}

h1 {
  text-align: center;
  color: var(--text-black);
  /* font-family: "Anton", sans-serif; */
}

h2 {
  color: var(--text-black);
  margin-bottom: 10px;
  /* font-family: "Anton", sans-serif; */
}

.input-section, .output-section {
  margin-bottom: 30px;
}

.job-description, .cv-selection, .additional-info {
  margin-bottom: 20px;
}

textarea, select, .input-section button {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--tetriary-color);
  border-radius: 4px;
  /* font-family: "Lato", sans-serif; */
  box-sizing: border-box;
}

textarea {
  resize: vertical;
  min-height: 100px;
  max-width: 100%;
}

select {
  background-color: var(--text-white);
}

.input-section button {
  background-color: var(--primary-color);
  color: var(--text-white);
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.input-sectionbutton:hover {
  background-color: var(--tetriary-color);
}

.no-cv-message {
  background-color: var(--secondary-color);
  border: 1px solid var(--tetriary-color);
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
}

.no-cv-message p {
  margin: 0 0 10px 0;
  color: var(--text-black);
}

.no-cv-message a {
  color: var(--primary-color);
  text-decoration: none;
}

.no-cv-message a:hover {
  text-decoration: underline;
}

.generated-application {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  margin-top: 20px;
  background-color: white;
}

.generated-application pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  /* font-family: "Lato", sans-serif; */
}

.edit-application-textarea {
  min-height: 300px;
  margin-bottom: 10px;
}

.edit-toggle-button {
  margin-top: 10px;
  background-color: var(--tetriary-color);
}

.edit-toggle-button:hover {
  background-color: var(--primary-color);
}

.application-text {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}

.application-metadata {
  background-color: var(--secondary-color);
  border: 1px solid var(--tetriary-color);
  border-radius: 4px;
  padding: 15px;
  margin-top: 20px;
  font-size: 0.9em;
}

.application-text pre,
.application-metadata pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}

button.generating {
  background-color: var(--tetriary-color);
  cursor: not-allowed;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.generating-message {
  animation: pulse 1.5s infinite;
  color: var(--primary-color);
  font-weight: bold;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 20px;
}

.edit-toggle-button,
.download-button {
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  flex: 0 1 auto;
  margin: 0;
}

.edit-toggle-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
}

.edit-toggle-button:hover {
  background-color: var(--primary-color-dark);
}

.download-button {
  background-color: transparent;
  color: var(--text-black);
  border: 1px solid var(--text-black);
}

.download-button:hover {
  background-color: var(--text-black);
  color: white;
}
