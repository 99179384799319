  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--primary-color);
    cursor: pointer;
  }
  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--primary-color);
    cursor: pointer;
  }

  span {
    fontSize: 14px;
     color: '#666' ;
  }
  input[type="range"] {
    accent-color: var(--primary-color);
}
  .textRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 8px;
  } 
  
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    outline: none;
  }

  .slider-container {
    width: 100%;
    max-width: 1300px;
    margin: 200px auto;
    position: relative;
  }

  .slider-container svg {
    /* width: 24px; */
    /* height: 24px; */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .slider-container .arrowRight {
    right: -30px;
    width: 100px;
    
  }

  .slider-container .arrowLeft {
    left: -30px;
    width: 100px;

  }

  .slider-container .arrowLeft:hover, .slider-container .arrowRight:hover {
    scale: 1.2;
  }

  @media (max-width: 768px) {
    .slider-container svg {
      /* display: none; */
      bottom: -10px;
      top: unset;
      
      
    }

    .arrowLeft {
      left: 0;
    }
    .template-slider .slider-container .arrowRight {
      right: 15%;
    } 

    .template-slider .slider-container .arrowLeft {
      left: 15%;
      
    } 
  }

  .selected-value {
    text-align: center;
    margin-top: 16px;
  } 

  select,
input[type="range"] {
    width: 100%;
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

input[type="range"] {
       padding: 0px;
}


@media (max-width: 768px) {
  .slider-container {
    margin: 0px;
  }
}