.cv-accordion {
  width: 95%;
  max-width: 800px;
  margin: 2rem auto;
  color: var(--text-white);
}

.cv-accordion h2 {
  text-align: center;
  margin-bottom: 1rem;
  color: var(--text-white);
}

.accordion-item {
  margin-bottom: 0.5rem;
  /* border: 1px solid #ddd; */
  border-bottom: 1px solid var(--text-white);
  border-radius: 4px;
  overflow: hidden;
}

.accordion-title {
  /* background-color: var(--text-white); */
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-white);
  background-color: #1a1a1b00;
}
.accordion-title  {
  font-size: 20px;
  font-weight: 400;
}
.accordion-title:after {
  content: '\002B';
  font-size: 20px;
  font-weight: bold;
  color: var(--text-white);
}

.accordion-title.active:after {
  content: "\2212";
  color: var(--text-white);
}

.accordion-title:hover, .accordion-title.active {
}

.accordion-content {
  /* background-color: var(--text-white); */
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: 0;
}

.accordion-content-inner {
  padding: 18px;
}

.accordion-title.active + .accordion-content {
  max-height: 1000px; /* Adjust this value based on your content */
}

@media (max-width: 768px) {
  .footer-mobile .accordion-item-footer button, .footer-mobile .accordion-item-footer button:after {
    color: var(--text-black);
  }
}