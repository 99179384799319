.resetPassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    padding: 20px;
}

.resetPassword h1 {
    text-align: center;
    margin-bottom: 30px;
    color: var(--text-black);
    font-size: 2rem;
}

.resetPassword form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 400px;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.resetPassword input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.resetPassword input:focus {
    outline: none;
    border-color: var(--primary-color);
}

.resetPassword button {
    background-color: var(--primary-color);
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    margin-top: 10px;
}

.resetPassword button:hover {
    background-color: var(--primary-color-dark);
}

.resetPassword .error {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 15px;
}

@media screen and (max-width: 480px) {
    .resetPassword form {
        padding: 20px;
        width: 90%;
    }

    .resetPassword h1 {
        font-size: 1.5rem;
    }
} 