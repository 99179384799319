.ctas {
  display: flex;
  flex-direction: row;
  width: 50%;
  flex-wrap: wrap;
  gap: 10px;
}

.ctas-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cta {
    display: flex;
    width: 160px;
    height: 51px;
    align-items: center;
    justify-content: center;
    background: var(--primary-color);
    border-radius: 180px;
    border: 0px solid var(--primary-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}




/* CSS */
.cta4 {
  align-items: center;
  background-color: var(--primary-color);
  border: 2px solid var(--text-black);
  border-radius: 8px;
  box-sizing: border-box;
  color: var(--text-black);
  cursor: pointer;
  display: flex;
  font-family: Inter,sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.cta4:after {
  background-color: var(--text-black);
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform .2s ease-out;
  z-index: -1;
}

.cta4:hover:after {
  transform: translate(0, 0);
  /* z-index: 0; */
}

.cta4:active {
  background-color: var(--primary-color);
  outline: 0;
}

.cta4:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .cta4 {
    padding: 0 40px;
  }
}

.cta:hover {
    position: relative;
    top: -10px;
    left: -10px;
    box-shadow: 18px 18px 0px #FFAC37;
}


.cta2 {
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    justify-content: center;
    background: var(--primary-color);
    color: var(--text-black);
    border: 0px solid var(--primary-color);
}

.cta2:hover {
    background: transparent;
    border: 2px solid var(--primary-color);
    color: var(--text-black);
    cursor: pointer;
}

.cta3 {
    width: 160px;
    height: 51px;
    background: var(--primary-color);
    border-radius: 180px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-black);
    gap: 10px;
}

.cta3:hover {
    background-color: #d1d0d0;
    color: var(--text-black);
}

.buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
    .cta, .cta2 {
        width: 100%;
    }
}

