input {
    box-sizing: border-box;
    width: 227px;
    /* min-width: 120px; */
    /* width: 40%; */
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 172, 55, 0.2);
    border-radius: 4px;
    margin: 15px 10px;
    padding-left: 10px;
}

input:hover {
    outline: 1px solid rgba(255, 172, 55, 0.8);

}

input:focus {
    outline: 1px solid rgba(255, 172, 55, 0.8);
    padding-left: 10px;

}

input::placeholder {
    padding-left: 10px;
}


.url {
    color: var(--text-black);
    padding: 0px 0px 10px 20px;
    display: flex;
    /* padding-bottom: 10px; */
    font-size: 0.8rem;
    font-weight: 400;
    justify-content: flex-end;
}

.input-wrapper {
    position: relative;
    display: inline-block;
    width: 227px;/* Adjust width as needed */
  }
  
  .input-field {
    /* width: 100%; */
    padding-right: 80px; /* Adjust padding to make space for the toggle */
    box-sizing: border-box;
  }
  
  .toggle {
    position: absolute;
    right: -10px; 
    transform: translateY(-90%);
    cursor: pointer;
    display: flex;
    align-items: center;
  }


  .toggle svg {
    width: 50px;
    }

  .toggleReference {
    width: 60px;
    margin-right: 20px;
    position: relative;
    top: 3px;
}

.toggleReference svg {
    width: 100%;
}

@media screen and (max-width: 660px) {
    input {
        /* width: 150%; */
        min-width: 100px;
        width: 100%;
    }

    .input-wrapper {
        width: 290px
    }
}