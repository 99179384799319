.contactForm h1 {
  text-align: center;
}
html {
  overflow-x: hidden;
}
.contactInfo {
  width: 494px;
  margin-top: 40px;
}

.contactInfo a {
  color: var(--text-black);
}

.contactInfo span {
  font-weight: bold;
  font-size: 1rem;
}

.contactForm {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* width: 100%; */
  align-items: center;
  margin-bottom: 100px;
}
.contactForm h2 {
  color: var(--text-white);
}
.contact-container-landing h1,
.contact-container-landing .contactInfo,
.contact-container-landing a,
.contact-container-landing p {
  color: var(--text-white);
}

.contact-container-landing .contactInfo {
  width: 55%;
}

.contact-container-landing .contactForm form {
  width: 55%;
 
}

.contact-container-landing .contactForm .cta2 {
    width: 20%;
    align-self: flex-end;
    justify-self: flex-end;
    border-radius: 55px;
    background-color: hsla(214, 28%, 95%, 0.30);
    color: var(--text-white);
    border: 1px solid hsla(13, 100%, 98%, 0.75);
}
.contact-container-landing .contactForm .cta2:hover {
    background-color: hsla(214, 28%, 95%, 0.30);
    color: var(--text-white);
    scale: 1.05;
    /* border: 1px solid hsla(13, 100%, 98%, 0.75); */
}

.contact-container-landing .contactForm form div {
  gap: 68px;
  margin-bottom: 20px;
}

.contact-container-landing .contactForm form div input,
.contact-container-landing .contactForm form div textarea {
  background-color: hsla(214, 28%, 95%, 0.30);
  border: 1px solid hsla(13, 100%, 98%, 0.75);
  border-radius: 10px;
}

.contact-container-landing .contactForm form div input::placeholder,
.contact-container-landing .contactForm form div textarea::placeholder {
    color: hsla(13, 100%, 98%, 0.75);
}

.contact-container-landing .contactForm form div input {
   margin-right: 0px;
   margin-left: 0px;
   width: 100%;
}

.contactForm form div {
  display: flex;
  flex-direction: row;
  /* width: 50%; */
  justify-content: center;
  align-content: center;
  align-items: center;
}

textarea {
  width: 464px;
  height: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 172, 55, 0.2);
  border-radius: 4px;
  margin-bottom: 20px;
  padding-left: 10px;
}

textarea:focus {
  outline: 1px solid rgba(255, 172, 55, 0.8);
  padding-left: 10px;
}

textarea::placeholder {
  padding-left: 10px;
}

.error-message {
  /* background-color: var(--danger-color); */
  background-color: #dc262651;
  padding: 30px 50px;
  color: var(--text-black);
  text-align: center;
}

.success-message {
  /* background-color: var(--danger-color); */
  background-color: #05966951;
  padding: 30px 50px;
  color: var(--text-black);
}

@media (max-width: 768px) {
  .contactForm form div {
    flex-direction: column;
  }
  .contact-container-landing .contactForm .cta2 {
    width: 100%;
    border-radius: 10px;
  }

  .contact-container-landing .contactForm form div {
    gap: 10px;
  }
  .contact-container-landing .contactForm form {
    width: 100%;
      }
  .contactInfo {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
  }
}
