/* Base ghost button styles */
.btn-ghost {
    /* Structure */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-radius: 12px;
    
    /* Typography */
    font-size: 14px;
    font-weight: 500;
    
    /* Colors */
    color: var(--shade-gray);  /* gray-600 */
    background: transparent;
    
    /* Transitions */
    transition: all 0.2s ease;
    cursor: pointer;
  }
  
  /* Hover state */
  .btn-ghost:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #1f2937;  /* gray-800 */
  }
  
  /* Active state */
  .btn-ghost:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  /* Focus state */
  .btn-ghost:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.2);
  }
  
  /* Disabled state */
  .btn-ghost:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
  
  /* Variant with border */
  .btn-ghost-bordered {
    border: 1px solid var(--shade-gray);  /* gray-200 */
  }
  
  .btn-ghost-bordered:hover {
    border-color: var(--shade-gray);  /* gray-300 */
  }
  
  /* Variant with icon */
  .btn-ghost-icon {
    gap: 8px;  /* Space between icon and text */
  }
  
  /* Size variants */
  .btn-ghost-sm {
    padding: 6px 12px;
    font-size: 12px;
  }
  
  .btn-ghost-lg {
    padding: 12px 24px;
    font-size: 16px;
  }