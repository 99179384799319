

.design-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* padding: 20px; */
    background-color: var(--background-color);
    width: 100%;
}

.cv-template-slider,
.font-selector,
.font-size-slider,
.color-picker {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    gap: 10px;
}


.template-slider {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}


.cv-template-slider {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 93%;
    min-height: 400px;
}

.cv-template-slider h2 {
margin: 20px 0px;
}

.template-slide-cv {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.template-container {
    display: flex;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
}

.slider-arrow {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--primary-color);
}

.template {
    /* border: 1px solid #ddd; */
    height: 200px;
    /* background-color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
}



.waterMark {
    color: #24232320;
    margin-bottom: 10px;
}


.color-option {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid transparent;
}

.color-option:hover,
.color-option.selected {
    border-color: var(--secondary-color);
}

.slider-arrow svg path {
    fill: var(--text-black);
    scale: 0.7;
}
.template-container-cv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.template {
    /* background-color: gray; */
    width: 150px;
    height: 230px;
    position: relative;

}

.template p {
    text-align: center;
}

.checkmarkTemplate {
    scale: 4;
    position: absolute;
    top: 40%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    /* padding-top: 5px; */
    /* padding-left: 7px; */
    
}

.checkmarkTemplate path {
    fill: var(--text-black);
}

.custom-slider [data-orientation="horizontal"] {
    height: 8px;
    background-color: var(--primary-color-opacity);
  }
  .custom-slider [role="slider"] {
    background-color: var(--primary-color);
    width: 20px;
    height: 20px;
  }
  .custom-slider [data-orientation="horizontal"] [data-disabled] {
    background-color: var(--primary-color);
    opacity: 0.5;
  }



input[type="color"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 40px;
    padding-left: unset;
    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}

input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
}

input[type="color"]::-moz-color-swatch {
    border: none;
    border-radius: 50%;
}



.primary-colors, .secondary-colors, .tertiary-colors {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.colorBox {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}


.primaryColor, .secondaryColor, .tertiaryColor {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0.2px solid #777777;
    
}

.clicked {
    /* box-shadow: inset 0 0 0 5px var(--text-white); */
    /* scale: 1.1;@ */
    border: 3px solid #777777;
}

.poppins {
    font-family: "Poppins";
}

.inter {
    font-family: "Inter";
}    

.ebGaramond {
    font-family: "EBGaramond";
    font-size: 36px;
}
.lato {
    font-family: "Lato";
}

.checkmark {
    scale: 1.2;
    padding-top: 5px;
    padding-left: 7px;

}
.checkmark path {
    fill: var(--text-black);
}
@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter.ttf");
  }
  
  @font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins.ttf"); 
  }
  
  @font-face {
    font-family: "EBGaramond";
    src: url("../fonts/EBGaramond.ttf"); 
  } 



  @media (max-width: 1000px) {
    .color-picker {
    margin-bottom: 100px;
    }
  }


@media screen and (max-width: 660px) {
    
    .colorBox{
        flex-wrap: wrap;
        align-items: stretch;
        flex-direction: row;
        gap: 5px;
        justify-content: flex-end;
        align-content: center;
        /* max-width: 80%; */
        width: 70%;
    }

    .primary-colors, .secondary-colors, .tertiary-colors {
        margin: 50px 0px;
    }

    input[type="color"] {
        width: 50px;
        height: 50px;
        min-width: unset;
        
    }

}