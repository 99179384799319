.popupModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}

.popupModalContent {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* background-color: white; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
}

.popupModalContentForm {
    background-color: var(--text-white);
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 100px;
    border-radius: 10px;
}

.popupModalContent input {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    /* border: 1px solid #ccc; */
    border: 2px solid var(--primary-color-opacity);
}

.popupModalContent button {
    margin: 10px 0;
    padding: 10px 30px;
    border-radius: 5px;
    border: 1px solid #ccc;
    align-self: flex-end;
}

.popupPayModalText {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    max-width: 50%;
}