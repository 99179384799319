:root {
  --primary-color: #FB8500;
  --primary-color-dark: #ff7b00;
  --primary-color-light: #ff9b3d;
  --primary-color-opacity: hsla(32, 100%, 49%, 0.172);
  --text-white: #ffffff;
  --text-black: #242424;
  --secondary-color: #FFF5EB;
  --shade-gray: ##4b5563;
  --tetriary-color: hsla(35, 100%, 61%, 0.60);
  --danger-color: #dc2626;
  --danger-color-dark: #b91c1c;
}

.wrapper {
  overflow-y: hidden;
}
#root {
  width: 100%;
}
body {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

html {
  overflow-x: hidden;
}

h1, h2 {
  /* font-family: "Anton"; */
  font-family: "proxima-nova", sans-serif;
font-weight: 700;
font-style: normal;
line-height: 1.1;

}

h1 {
  text-align: center;
}

a, p, div, input::placeholder, textarea::placeholder, input, textarea {
  font-family: "Lato";
}


.login {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;


}

.wrapper.login {
  min-height: 45vh;
}

.login form {
  min-width: 464px;
}
.login form input {
  width: 100%;
}

.login div a {
  color: var(--text-black);
}

.createUser {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 45vh;
}

.createUser form {
  display: flex;
  flex-direction: column;

}

.heroSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.heroSection div {
  flex: 1;
}

@font-face {
  font-family: "Anton";
  src: url("../src/fonts/Anton-Regular.ttf");
}

@font-face {
  font-family: "Lato";
  src: url("../src/fonts/Lato.ttf");
}

@media screen and (max-width: 660px) {
  .wrapper {
    margin: 0px 15px;

  }

  .login form {
    /* min-width: 100%; */
    min-width: unset;
    width: 80vw;

  }

  .createUser form {
    width: 80vw;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
  }
}
