.template-selection {
    padding: 2rem;
    text-align: center;
    transition: opacity 0.5s ease-in-out;
    min-height: 500px; /* Set minimum height to 500px */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.template-selection.fade-out {
    opacity: 0;
}

.template-slider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 2rem 0 0 0; */
    height: 100%;
    padding-bottom: 50px;
}

.template-slider p {
    color: var(--text-white);
    width: 50%;
    text-align: center;
}

.template-container {
    /* max-width: 600px; */
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.template-item {
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.template-item:hover {
    /* Remove hover effect as requested */
}

.cvTemplateImages {
    /* max-width: 100%; */
    /* max-height: 100%; */
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cvTemplateImages:hover {
    cursor: pointer;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.arrow-left {
    left: 200px;
}

.arrow-right {
    right: 200px;
}


.arrow-left path {
    fill: var(--primary-color);
    
}

.arrow-right path {
    fill: var(--primary-color);
}

.progress-dots {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--primary-color-light);
    margin: 0 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.dot.active {
    width: 20px;
    border-radius: 5px;
    background-color: var(--primary-color);
}

@media (max-width: 768px) {
    .arrow {
        position: absolute;
        /* top: auto; */
        top: unset;
        bottom: 0px;
    }

    .arrow-left {
        left: calc(50% - 30px);
        width: 20px;
    }
    
    .arrow-right {
        right: calc(50% - 30px);
        width: 20px;
    }

    .progress-dots {
        margin-top: 1rem;
    }

    .template-container {
        width: 90%;
}

.template-slider p, .template-slider h2 {
    color: var(--text-white);
    width: 100%;
    text-align: center;
}
}
