.landing-page {
  margin: 0 auto;
  /* padding: 2rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #cbc2c7;
  background-image: url("../../public/img/backgroundSplash.png");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}
.blob,
.blob1,
.blob2,
.blob3,
.blob4 {
  box-sizing: border-box;
  border-radius: 50%;
  position: absolute;
  background: #fac600;
  width: 165px;
  height: 165px;
}

.blob {
  left: 250px;
  top: 200px;
}
/*first to the left*/

/* .blob1 {
  top: 500px;
  left: 500px;
  width: 165px;
  height: 165px;
} */
/*center*/

.blob2 {
  top: 800px;
  /* left: unset; */
  left: 250px;
  width: 260px;
  height: 260px;
}
/*down to the right*/

.blob3 {
  top: 450px;
  right: 0px;
  left: unset;
  /* width: 360px; */
  /* height: 360px; */
}

/*up to the right*/

/* .blob4 {
  bottom: -180px;
  left: 100px;
} */
/*down to the left*/

.hero-section {
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 4rem;
  /* margin-top: 4rem; */
  padding: 4rem;
  /* border: 1px solid red; */
  /* Hero Cont */

  box-sizing: border-box;
  margin-top: 30px;

  /* position: absolute; */
  width: 1300px;
  height: 1000px;
  left: calc(50% - 1300px / 2);
  top: -55px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.4);
  border-radius: 60px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.1px);
  -webkit-backdrop-filter: blur(10.1px);
  border: 1px solid rgba(255, 255, 255, 0.65);
  justify-content: space-evenly;
  /* background: rgba(239, 242, 246, 0.3);
  box-shadow: 15px 4px 20px rgba(5, 5, 27, 0.06);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */
  /* border-radius: 60px; */
}

.hero-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

.hero-content {
  flex: 1;
  padding-right: 2rem;
}

.hero-content h1 {
  text-align: left;
}

.hero-image {
  flex: 1;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* animation: slideInLeft 0.9s ease-in-out; */
}

h1 {
  font-size: 3rem;
  animation: fadeIn 0.9s ease-in-out;
}

h2 {
  font-size: 2rem;
  animation: fadeIn 0.9s ease-in-out;
}

.hero-content p {
  font-size: 20px;
  line-height: 1.5;
}

section {
  padding: 100px 0px;
}
.hero-image img {
  border-radius: 10px;
  height: 100%;
}
#yellowBackgroundBlob, #blueBackgroundBlob, #blackBackgroundBlob, #backgroundFooterYellow, #backgroundFooterBlue, #backgroundFooterBlack {
  width: 100%;
}
/* #yellowBackgroundBlob, #blueBackgroundBlob, #blackBackgroundBlob {
  height:  2400px;
} */
#yellowBlob {
  position: absolute;
  top: 20px;
  right: 70px;
  z-index: 0;
  scale: 1.1;
}

#blueBlob {
  position: relative;
  z-index: 0;
  left: 30px;
  width: 100%;
  height: 125%;
}

#imageHeroBlob {
  position: absolute;
  width: 100%;
  z-index: 2;
  scale: 1.1;
  top: 20px;
  right: -50px;
}

#blobContainer {
  position: absolute;
  top: -75px;
  width: 100%;
  height: 125%;
  z-index: 2;
}


.template-slider {
  margin-bottom: 4rem;
  overflow: hidden;
  width: 100%;
  z-index: 3;
  position: relative;
  padding: 2rem 0;
}

.template-slider h2 {
  color: var(--text-white);
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  padding: 0 2rem;
}

.slider-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  padding: 0 1rem;
}

.templates-wrapper {
  display: flex;
  gap: 2rem;
  transition: transform 0.3s ease;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.template-preview {
  flex: 1;
  max-width: 400px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.template-preview:hover {
  transform: scale(1.02);
}

.cvTemplateImages {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.arrowLeft, .arrowRight {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.arrowLeft:hover, .arrowRight:hover {
  transform: scale(1.1);
}

.pricing-section {
  position: relative;
  text-align: center;
  /* background-color: var(--text-white); */
  width: 100%;
}
.pricing-section .subscription-text {
  /* position: absolute; */
  /* padding-top: 20px; */
  /* padding-bottom: -10px; */
  color: var(--text-white);
  /* position: relative; */
  /* bottom: -50px; */
}

.subscription-container {
  position: relative;
  bottom: -50px;
  margin-top: 150px;
}
.vipps-badge-container {
  width: 15%;
  height: auto;
  display: flex;
  justify-self: center;
  align-self: center;
}
.blobBlue,
.blobBlue2,
.blobBlue3 {
  position: absolute;
  background: #0077b5;
  border-radius: 50%;
  left: 50px;
  width: 208px;
  height: 208px;
}

.blobBlue2 {
  /* top: 400px; */
  left: unset;
  bottom: 500px;

  right: 0px;
}

.blobBlue3 {
  bottom: -30px;
  left: 500px;
}

.pricing-box-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;

  /* Section: Prices */

  box-sizing: border-box;

  /* position: absolute; */
  /* width: 1166px; */
  /* height: 1443px; */
  /* left: calc(50% - 1166px/2); */
  /* top: 5208px; */

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 21px;
}

.pricing-container {
  background-color: #1a1a1b;
  flex-direction: column;
  padding: 4rem 8rem;
  min-height: 500px;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 60px;
  background: rgba(11, 11, 16, 0.65);
  box-shadow: 15px 4px 20px rgba(5, 5, 27, 0.06);
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.35);
}
.pricing-container h2, .pricing-container p {
  color: var(--text-white);
}

.pricing-box {
  /* border: 1px solid #ccc; */
  background-color: rgba(11, 11, 16, 0.65);
  border-radius: 21px;
  padding: 2rem;
  width: 350px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.35);
}

.why-choose-us {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  /* padding: 200px; */
  /* min-height: 1000px; */
  /* width: 80%; */
}

.why-choose-us-container {
  width: 50%;
  /* animation: slideInLeft 0.9s ease-in-out; */
  margin-top: -200px;
  /* margin-top: 50px; */
}
.why-choose-us-container h2 {
  font-size: 47px;
  font-weight: 200;
  margin-bottom: 50px;
}
.why-choose-us-container p {
  max-width: 400px;
}

.why-choose-us-container p a {
  color: #1a1a1b;
  text-decoration: underline;
}

.why-choose-us-container .cta {
  margin-top: 40px;
  text-decoration: none;
}

.why-choose-us-image {
  width: 50%;
  /* animation: slideInRight 0.9s ease-in-out; */
  justify-content: flex-end;
  position: relative;
}


#imageBlob {
  width: 107%;
  height: 100%;
}

#blueBlob {
  position: relative;
  z-index: 0;
  left: 30px;
  /* position: absolute; */
  /* top: -75px; */
  /* left: 75px; */
  width: 100%;
  height: 125%;
}

.pricing-box h3 {
  margin-bottom: 1rem;
  color: var(--text-white);
}

.pricing-box ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  color: var(--text-white);
}

.pricing-box li {
  margin-bottom: 0.5rem;
  color: var(--text-white);
}

.cta-container {
  display: flex;
  justify-content: center;
  /* margin-top: 2rem; */
  margin: 2rem 0rem;
}

.cta-button {
  /* display: inline-block; */
  background-color: var(--primary-color);
  color: white;
  padding: 1.2rem 3rem;
  text-decoration: none;
  border-radius: 180px;
  transition: background-color 0.3s ease;
  /* margin-top: 4rem; */
}
.freeCTA {
  background-color: lightgray;
  color: var(--text-black);
}

.cta-button:hover {
  background-color: var(--primary-color);
  scale: 1.05;
}

.freeCTA:hover {
  background-color: gray;
  color: var(--text-white);
}

.arrowRight path,
.arrowLeft path {
  fill: var(--primary-color);
  /* scale: 1.2; */
}

.cvTemplateImages {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}



.cv-info-section {
  /* background-color: #1A1A1B; */
  width: 100%;
  z-index: 5;
  position: relative;
}
.cv-info-container {
  margin-top: 200px;
}
.cv-info-section .cv-info-container h2 {
  text-align: center;
  color: var(--text-white);
}
.features-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25%;
  z-index: 3;
  justify-content: center;
  padding-left: 15%;
  padding-right: 15%;
  /* background-color: #1A1A1B; */
}

.feature-image {
  width: 150px;
  height: auto;
  border-radius: 180px;
}
.features-container {
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.feature p {
  width: 200px;
}

.feature:nth-child(3) {
  /* animation: slideInRight 0.9s ease-in-out; */
  animation-delay: 0.4s;
}
.feature:nth-child(2) {
  /* animation: slideInRight 0.9s ease-in-out; */
  animation-delay: 0.8s;
}
.feature:nth-child(1) {
  /* animation: slideInRight 0.9s ease-in-out; */
  animation-delay: 1s;
}

.feature p,
.feature h3,
.features-section h2,
.features-section p {
  text-align: center;
  color: var(--text-white);
}
.background-divs {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 2800px;
}

.background-divs-footer {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 200px;
  /* max-height: 400px; */

  min-height: 2100px;
}
.background-blob-yellow-footer {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 1;
}

.background-blob-blue-footer {
  position: absolute;
  top: -50px;
  left: 0;
  z-index: 0;
}
.background-blob-black-footer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.background-blob-yellow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.background-blob-blue {
  position: absolute;
  top: 600px;
  left: 0;
  z-index: 0;
}
.background-blob-black {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

/* 
.background-blob-yellow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../public/img/svgBackgrounds/yellowBackground.svg");
} */

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    min-height: 1300px;
    border-radius: 0px 0px 20px 20px;
    margin-top: 0px;
  }
  .template-slider a > div {
    display: none;
  }
  .landing-page {
    padding: 0;
    /* overflow-x: hidden; */
    /* background-color: #ffffff; */
    background-image: none;
    background: rgb(208, 165, 148);
    background: linear-gradient(
      139deg,
      rgba(208, 165, 148, 1) 0%,
      rgba(212, 178, 187, 1) 16%,
      rgba(208, 148, 148, 0.9555480072463768) 35%,
      rgba(156, 154, 178, 1) 51%,
      rgba(156, 154, 178, 1) 71%,
      rgba(208, 165, 148, 1) 88%,
      rgba(208, 165, 148, 1) 100%
    );
  }

  .blob,
  .blob1,
  .blob2,
  .blob3,
  .blob4,
  .blobBlue3,
  .blobBlue2,
  .blobBlue {
    display: none;
  }

  #imageHeroBlob {
    right: unset;
  }
  .hero-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 1000px;
    margin-top: 0px;
  }

  .hero-image,
  .hero-section {
    width: 100%;
    /* min-height: 1000px; */
  }

  .hero-section {
    padding: 4rem;
    margin-bottom: 0px;
  }

  .hero-image {
    height: 100%;
  }

  #yellowBlob {
    position: relative;
    top: 25px;
    right: 25px;
    z-index: 1;
  }

  .hero-image img {
    width: 100%;
    height: auto;
  }

  h1 {
    font-size: 3rem;
    text-align: center;
  }

.hero-section h1{
  margin: 0px;
}

  .hero-section {
    padding: 2rem;
    margin-top: 0px;
  }

  .hero-content {
    padding-right: 0;
    /* margin-bottom: 2rem; */
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  section {
    padding: 2rem 0px;
  }

  .pricing-box {
    width: 80%;
    /* background-color: var(--text-white); */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: var(--text-white);
  }
  .features-container {
    /* display: flex; */
    /* width: 100%; */
    flex-direction: column;
    align-items: flex-start;
  }

  .features-section p {
    width: 80%;
  }

  .slider-container .arrowLeft {
    /* left: 20px; */
    /* left: 0px !important; */
    /* display: block !important; */
  }
  .slider-container .arrowRight {
    right: 0px;
  }

  .templates-wrapper {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }

  .background-divs {
    /* min-height: 3000px; */
  }

  .background-divs-footer {
    min-height: 2000px;
    display: none;
  }

  .background-blob-black svg {
    height: 3100px;
    display: none;
  }

  .template-preview h3 {
    color: var(--text-white);
  }

  .background-blob-yellow {
    top: 150px;
    display: none;
  }
  .features-section {
    margin-top: 300px;
    background-color: #1a1a1b;
  }

  .background-blob-blue svg {
    display: none;

    /* height:2500px; */
  }

  .pricing-container {
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 12px;
  }

  .template-preview {
    flex: 0 0 100%;
    scroll-snap-align: center;
    margin-bottom: 2rem;
    flex-direction: column;
    align-items: center;
  }

  .templates-wrapper {
    max-width: 100%;
    overflow-x: hidden;
  }

  .arrowLeft,
  .arrowRight {
    /* display: none; */
  }

  .why-choose-us {
    flex-direction: column;
    gap: 2rem;
    max-width: 80%;
  }

  .why-choose-us-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }

  .why-choose-us-container a {
    justify-self: center;
    align-self: center;
    color: #1a1a1b;
  }

  .features-section h2 {
    text-align: center;
  }

  .why-choose-us-image {
    width: 100%;
  }

  .pricing-box-container {
    flex-direction: column;
    align-items: center;
  }

  .subscription-container {
    margin-top: 0px;
    margin-bottom: 50px;
  }
}

/* Slide-down and fade-in animation */
@keyframes fadeIn {
  0% {
    /* transform: translateY(-100px); Start position above the viewport */
    opacity: 0; /* Start invisible */
  }
  20% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.6;
  }
  100% {
    /* transform: translateY(0); End position in the viewport */
    opacity: 1; /* Fully visible */
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(-600px); /* Start position above the viewport */
    opacity: 0;
  }
  100% {
    transform: translateX(0); /* End position in the viewport */
    opacity: 1;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-600px); /* Start position above the viewport */
    opacity: 0;
  }
  100% {
    transform: translateX(0); /* End position in the viewport */
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .template-slider {
    padding: 1rem 0;
  }

  .slider-container {
    padding: 0 1rem;
  }

  .templates-wrapper {
    gap: 1rem;
  }

  .template-preview {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .arrowLeft, .arrowRight {
    width: 24px;
    height: 24px;
  }
}

.design-options {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  margin: 1rem 0;
}

.design-options h2 {
  /* font-size: 1.2rem; */
  margin-bottom: 1rem;
  color: var(--text-black);
}

.toggle-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
 
}

.toggle-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.toggle-switch {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.toggle-switch input {
  display: none;
}

.toggle-item .toggle-label {
  color: var(--text-black);
}

.toggle-slider {
  position: relative;
  width: 48px;
  height: 24px;
  background-color: #ccc;
  border-radius: 24px;
  transition: background-color 0.3s;
}

.toggle-slider:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  top: 2px;
  left: 2px;
  transition: transform 0.3s;
}

.toggle-switch input:checked + .toggle-slider {
  background-color: var(--primary-color);
}

.toggle-switch input:checked + .toggle-slider:before {
  transform: translateX(24px);
}

.toggle-label {
  color: var(--text-white);
  font-size: 0.9rem;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .design-options {
    padding: 1rem;
  }

  .toggle-switch {
    font-size: 0.8rem;
  }

  .toggle-slider {
    width: 40px;
    height: 20px;
  }

  .toggle-slider:before {
    width: 16px;
    height: 16px;
  }

  .toggle-switch input:checked + .toggle-slider:before {
    transform: translateX(20px);
  }

}

  /* @media (min-width: 1700px) { */
    @media (min-width: 1700px) {
    .navbar ul {
      max-width: 1400px;
    }


    .blob3 {
      right: 200px;
    }
  }

.profile-image-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    position: relative;
}

.image-preview {
    position: relative;
    display: inline-block;
}

.image-preview img {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.2s ease;
}

.image-preview img:hover {
    transform: scale(1.05);
}

.image-preview button {
    opacity: 0;
    transition: opacity 0.2s ease;
}

.image-preview:hover button {
    opacity: 1;
}

@media (max-width: 768px) {
    .profile-image-section {
        margin: 10px 0;
    }
    
    .image-preview img {
        width: 120px !important;
        height: 120px !important;
    }
}

.profile-upload-section {
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.profile-preview-row {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}

.profile-preview-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.remove-image-btn {
    background: #ff4444;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 0;
    transition: background-color 0.2s ease;
}

.remove-image-btn:hover {
    background: #cc0000;
}

@media (max-width: 768px) {
    .profile-preview-image {
        width: 50px;
        height: 50px;
    }
    
    .remove-image-btn {
        width: 18px;
        height: 18px;
        font-size: 12px;
    }
}
