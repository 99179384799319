.gridContainer {
  display: grid;
  width: 100vw;
  min-height: 800px;
  grid-template-columns: repeat(6, 1fr);
  /* grid-template-columns: 1.3fr 2fr 2fr; */
  /* grid-template-areas: "aside builder builder builder builder builder"; */
  grid-template-areas: "aside builder builder preview preview preview";
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}
.login a {
  color: var(--text-black);
  /* color: var(--text-white); */
  text-decoration: none;
}
.aside {
  grid-area: aside;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: url("../../public/img/backgroundSplashMobil.png");
  background-attachment: fixed;
  background-size:auto;
}


.aside div {
  color: var(--text-black);
  /* color: var(--text-white); */
  font-size: 18px;
  margin: 50px 0px;
}

.aside .cta2 {
  border-radius: 180px;
  width: 160.5px;
  height: 51px;
}

.builder {
  grid-area: builder;
  overflow-y: scroll;
  max-height: 120vh;
  /* max-width: 1000px; */
}

.cvTemplatePreview {
  grid-area: preview;
  background-color: var(--secondary-color);
  background-image: url("../../public/img/backgroundSplash.png");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  display: flex;
  height: 100%;
  /* width: 800px; */
  flex-direction: column;
  /* flex-direction: row; */
  justify-content: center;
}

span {
  font-size: 22px;
}

.inputs {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  width: 103%;
}

.personaliaBox {
  border: 1px solid var(--tetriary-color);
  margin: 10px 20px;
  border-radius: 8px;
}

.personaliaBoxPad {
  padding: 20px;
}

.moreButton {
  display: flex;
  justify-content: flex-end;
}

.inputsBox {
  border: 2px dashed var(--primary-color-opacity);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.starsBox {
  display: flex;
  width: 80%;
  align-items: center;
  flex-direction: row;
  column-gap: 20px;
  justify-content: space-evenly;
  /* flex-direction: row-reverse; */
}

.starsBox .trash-icon {
  margin-top: 12px;
}

.sectionBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sectionBoxRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.ai-icon svg {
  height: 30px;
}

.builderWrapper {
  margin: 80px 30px;
}

.referenceBox {
  display: flex;
  width: 100%;
  align-items: center;
  top: -20px;

  /* justify-content: flex-start; */
}

.stars {
  display: flex;
}

.stars svg {
  max-width: 30px;
}
.previewContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewContainer svg {
  margin: 10px;
}

.arrowRight path:hover,
.arrowLeft path:hover {
  fill: var(--primary-color);
}
.arrowRight {
  position: relative;
  right: -300px;
  bottom: 500px;
  scale: 1;
}

.arrowLeft {
  position: relative;
  left: -300px;
  bottom: 500px;
  scale: 1;
}

.previewBox {
  /* display: none; */
  /* why am i using this previewbox for watermark? */
  max-width: 60%;
  max-height: calc(60% * 1.4142);
  width: 450px;
  height: calc(450px * 1.4142);
  /* background-color: var(--text-white); */
}

.starIcon path:hover,
.starIcon:hover {
  /* fill: var(--tetriary-color); */
  fill: #FFAC37;
}

.filled path {
  fill: #FFAC37;
  /* fill: var(--tetriary-color); */
}

.waterMark {
  z-index: 1;
  transform: rotate(-45deg);
  font-size: 3rem;
  font-family: "Anton";
  letter-spacing: 0.4rem;
  /* color: red; */
  color: rgba(38, 35, 35, 0.1);
  /* text-align: center; */
  position: absolute;
  bottom: 300px;
  right: 100px;
  pointer-events: none;
  user-select: none;
}

.cvTemplatePreview .cta2 {
  width: 150px;
  border-radius: 180px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 1000;
}

.cvTemplatePreview .cta2:hover {
  border: 2px solid var(--primary-color);
  color: var(--text-black);
}

.previewMobile {
  display: none;
}
.addMoreSections {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addMoreSections .buttons {
  display: flex;
  flex-direction: row;
  column-gap: 50px;
  row-gap: 20px;
  justify-content: space-evenly;
  /* justify-content: flex-start; */
  align-items: center;
  margin: 20px 0px 0px 20px;

}

.downloadablePDF {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
}

.downloadablePDF a {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--text-white);
  padding: 10px 20px;
  border-radius: 180px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.downloadablePDF a:hover {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.template-selection {
  padding: 2rem;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.template-selection.fade-out {
  opacity: 0;
}

.template-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.template-container {
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  max-width: 400px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

.template-container:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.template-container h2 {
  margin-bottom: 1rem;
}

.template-preview {
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.template-preview h3 {
  color: var(--text-white);
}

.gridContainer.fade-in {
  opacity: 0;
}




.updating {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 20px;
  font-size: 14px;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.updating.visible {
  opacity: 0.9;
  transform: translateY(0);
}

/* Add a subtle pulse animation */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.updating.visible {
  opacity: 0.9;
  transform: translateY(0);
  animation: pulse 2s infinite;
}

.component-wrapper {
  position: relative;
  width: 100%;
}

.component-header {
  position: absolute;
  top: 10px;
  right: 30px;
  z-index: 1;
}

.component-header .trash-icon {
  cursor: pointer;
  padding: 5px;
}

.component-header .trash-icon:hover {
  opacity: 0.8;
}

.loginMenu a:active {
  color: var(--text-black);
}

@media screen and (max-width: 660px) {
  .gridContainer {
    display: grid;
    width: 100vw;
    min-height: 800px;
    grid-template-columns: 1fr;
    /* grid-template-columns: 1.3fr 2fr 2fr; */
    grid-template-areas:
    "builder"
    "preview"
    "aside";
  }

  .previewContainer {
    display: none;
  }

  .aside {
    display: flex;
    flex-direction: row;
    max-height: 40px;
    width: 100%;
    justify-content: space-between;
    background-image: url("../../public/img/backgroundSplashMobil.png");
    padding: 20px 0px;
    gap: 30px;
    margin: 0;
    z-index: 1000;
    bottom: 0;
    position: fixed;
   
  }
  .builder {
    max-height: unset;
  }
  .aside div {
    margin: 0;
  }

  .aside .cta {
    display: none;
  }

  .aside .logo {
    display: none;
  }
  .aside .innhold {
    margin-left: 10px;
  }
  .aside .profile {
    margin-right: 10px;
  }

  .aside div {
    /* margin: 35px 0px; */
  }

  /* .previewMobile {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--primary-color);
    border-radius: 50%;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  } */

  .previewMobile button {
    background-color: var(--primary-color);
  }

  .loginMenu {
    display: none;
  }

  .previewIcon {
    width: 30px;
    height: 30px;
    color: var(--text-white);
  }

  .previewMobile:hover {
    /* background-color: var(--secondary-color); */
    cursor: pointer;
  }

  .cvTemplatePreview.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: white;
    overflow-y: auto;
  }

  .cvTemplatePreview.fullscreen .previewContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cvTemplatePreview.fullscreen .arrowLeft,
  .cvTemplatePreview.fullscreen .arrowRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .cvTemplatePreview.fullscreen .arrowLeft {
    left: 20px;
    display: none;
  }

  .cvTemplatePreview.fullscreen .arrowRight {
    /* right: 20px; */
    display: none;
  }

  .cvTemplatePreview.fullscreen .previewContainer > *:not(.arrowLeft):not(.arrowRight) {
    max-width: 100%;
    max-height: 100%;
  }

  .document {
    width: 100% !important;
  }

  .wrapper {
    margin: 0px;
  }

  .builder {
    margin-bottom: 100px;
  }

  .builderWrapper {
    margin: 50px 0px;
  }

  .addMoreSections {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .addMoreSections .buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .template-preview {
    height: auto;
  }

  .template-container {
    min-height: 100%;
  }

  .fullscreen .cta2 {
    position: absolute;
    bottom: 20px;
    left: -100px;
    z-index: 300;
  }


  

  /* #root > div > div > div.preview.fullscreen > div.previewContainer > document */
}

@media screen and (max-width: 1000px) {
  .hide {
    display: none;
  }
  .gridContainer {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-areas:
      "builder"
      "preview"
      "aside";
  }

  .builder {
    grid-area: builder;
    /* width: 400px; */
  }

  .cvTemplatePreview {
    grid-area: preview;
    display: none;
    /* max-width: 50%; */
    /* display: none; */
  }

  .previewMobile {
    display: inline;
    
  }
 

  .fullscreen {
    display: grid;
    grid-area: preview;
  }

  .aside {
    grid-area: aside;
    flex-direction: row;
    display: flex;
    width: 100%;
    /* position: absolute; */
    bottom: 0px;
    justify-content: space-around;
    background-color: var(--text-white);
  }

  .aside .login {
    width: auto;
  }


  .aside div {
    margin: 20px 0px;
  }
}
@media only screen and (min-device-width : 600px) and (max-device-width : 1500px) {
  /* Add styles for MacBook Pro screen here */
  .gridContainer {
    display: grid;
    width: 100vw;
    min-height: 800px;
    grid-template-columns: repeat(7, 1fr);
    /* grid-template-columns: 1.3fr 2fr 2fr; */
    /* grid-template-areas: "aside builder builder builder builder builder"; */
    grid-template-areas: "aside builder builder builder preview preview preview";
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
  }
}

