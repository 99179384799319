/* Danger Button Variations */

/* Solid Danger Button */
.btn-danger {
    /* Structure */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    border: none;
    border-radius: 12px;
    
    /* Typography */
    font-size: 14px;
    font-weight: 500;
    margin-top: 40px;
    /* Colors */
    background-color: var(--danger-color);  /* red-600 */
    color: white;
    
    /* Transitions */
    transition: all 0.2s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  
  .btn-danger:hover {
    background-color: var(--danger-color-dark);  /* red-700 */
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(220, 38, 38, 0.25);
  }
  
  .btn-danger:active {
    transform: translateY(0);
    box-shadow: none;
  }
  
  /* Ghost Danger Button */
  .btn-danger-ghost {
    /* Structure */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-radius: 6px;
    
    /* Typography */
    font-size: 14px;
    font-weight: 500;
    
    /* Colors */
    color: var(--danger-color);  /* red-600 */
    background: transparent;
    
    /* Transitions */
    transition: all 0.2s ease;
    cursor: pointer;
  }
  
  .btn-danger-ghost:hover {
    background-color: rgba(220, 38, 38, 0.08);
    color: var(--danger-color-dark);  /* red-700 */
  }
  
  /* Outlined Danger Button */
  .btn-danger-outlined {
    /* Structure */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border: 1.5px solid var(--danger-color);  /* red-600 */
    border-radius: 6px;
    
    /* Typography */
    font-size: 14px;
    font-weight: 500;
    
    /* Colors */
    color: var(--danger-color);  /* red-600 */
    background: transparent;
    
    /* Transitions */
    transition: all 0.2s ease;
    cursor: pointer;
  }
  
  .btn-danger-outlined:hover {
    background-color: rgba(220, 38, 38, 0.08);
    border-color: var(--danger-color-dark);  /* red-700 */
    color: var(--danger-color-dark)  /* red-700 */
  }
  
  /* Common states for all variants */
  .btn-danger:disabled,
  .btn-danger-ghost:disabled,
  .btn-danger-outlined:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    transform: none;
    box-shadow: none;
  }
  
  /* Focus states */
  .btn-danger:focus,
  .btn-danger-ghost:focus,
  .btn-danger-outlined:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 0 2px rgba(220, 38, 38, 0.25);
  }
  
  /* Size variants */
  .btn-danger-sm {
    padding: 6px 12px;
    font-size: 12px;
  }
  
  .btn-danger-lg {
    padding: 12px 24px;
    font-size: 16px;
  }
  
  /* Ripple effect for solid variant */
  .btn-danger::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, rgba(255, 255, 255, 0.3) 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .3s, opacity 0.8s;
  }
  
  .btn-danger:active::after {
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
  }