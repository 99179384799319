.nav-wrapper {
    width: 100%;
    position: relative;
    margin-top: 40px;
}

.nav-wrapper2 {
    position: absolute;
    width: 100%;
    margin-top: 40px;
}

.navbar li {
    list-style-type: none;
}

.navbar {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.navbar-links {
    width: 400px;
    justify-content: space-evenly;
}

.navbar-cta {
    justify-content: space-evenly;
    align-items: center;
    width: 300px;
}

.navbar ul {
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: space-evenly;
    align-items: center;
    
    /* background-color: white; */
    /* background: linear-gradient(90deg, rgba(255, 254, 254, 0) 0%, rgba(233, 196, 106, 0.045) 100%); */
    /* filter: drop-shadow(0px 28px 28px rgba(0, 0, 0, 0.03)); */
    /* border-radius: 180px; */
    /* box-shadow: 0px 28px 28px rgba(0, 0, 0, 0.03); */
    z-index: 1000;
}

.navbar div {
    display: flex;
    flex-direction: row;
}

.navbar a {
    text-decoration: none;
    color: var(--text-black);
}

.navbar-mobile {
    display: none;
}

/* Mobile styles */
@media screen and (max-width: 660px) {
    .navbar {
        display: none;
    }

    .nav-wrapper2 {
        z-index: 1000;
        margin-top: 0;
        position: relative;
        width: 100%;
    }

    .navbar-mobile {
        display: block;
        width: 100%;
        position: relative;
    }

    .mobile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        background-color: white;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }

    .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        height: 21px;
        cursor: pointer;
        z-index: 1000;
    }

    .hamburger span {
        display: block;
        width: 100%;
        height: 3px;
        background-color: var(--primary-color-dark);
        border-radius: 3px;
        transition: all 0.3s ease;
    }

    /* Hamburger animation */
    .hamburger.open span:first-child {
        transform: translateY(9px) rotate(45deg);
    }

    .hamburger.open span:nth-child(2) {
        opacity: 0;
    }

    .hamburger.open span:last-child {
        transform: translateY(-9px) rotate(-45deg);
    }

    .mobile-menu {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        transform: translateY(-100%);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    }

    .mobile-menu.open {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }

    .mobile-menu a {
        text-decoration: none;
        color: var(--text-black);
        font-size: 1.1rem;
        padding: 10px 0;
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }

    .mobile-menu a:last-child {
        border-bottom: none;
    }

    .mobile-cta {
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }
}

.cta a {
    color: var(--text-black);
    text-decoration: none;
}

@media (min-width: 1700px) {
    .navbar ul {
      max-width: 1400px;
    }


  }