.rich-text-editor {
    border: 1px solid #fce8d3;
    border-radius: 4px;
    padding: 8px;
    /* font-family: Arial, sans-serif; */
    width: 100%;
    box-sizing: border-box;
    margin: 10px;
  }
  
  .toolbar {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #fce8d3;
    padding-bottom: 4px;
    margin-bottom: 8px;
  }
  
  .toolbar-button {
    border: none;
    background: none;
    cursor: pointer;
    margin-right: 8px;
    font-size: 16px;
    color: var(--shade-gray);
  }
  
  .toolbar-button:hover {
    color: var(--text-black);
  }
  
  .text-area {
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    font-size: 14px;
    color: var(--shade-gray);
    min-height: 80px;
    padding: 0;
  }
  
  .text-area::placeholder {
    color: var(--shade-gray);
  }
  

.modal {
    display: none;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: var(--text-black);
    background-color: var(--text-black)
}

.modal-content {
    background-color: var(--text-white);
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
}

.accept-button, .regenerate-button {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.accept-button {
  background-color: var(--primary-color);
}

.regenerate-button {
  margin-left: 10px;
  background-color: var(--text-black);
}

.close {
  color: var(--shade-gray);
  display: flex;
  justify-content: end;
  font-size: 28px;
  font-weight: bold;
}

