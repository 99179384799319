.cv-preview-thumbnail {
  width: 180px;
  height: 250px;
  position: relative;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 5px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cv-preview-thumbnail:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Paper effect */
.cv-preview-thumbnail::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(45deg, transparent 98%, #ddd 98.5%);
  pointer-events: none;
  z-index: 2;
}

.cv-preview {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Split layout (sidebar + main content) */
.split-layout {
  display: flex;
  flex-direction: row;
}

.split-layout .preview-sidebar {
  width: 35%;
  height: 100%;
  padding: 8px;
}

.split-layout .preview-main {
  width: 65%;
  padding: 8px;
}

.split-layout .preview-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin: 5px auto 10px;
}

/* Top header layout */
.top-header-layout .preview-header {
  padding: 8px;
  color: white;
}

.top-header-layout .preview-main {
  padding: 8px;
}

/* Default and other layouts */
.default-layout .preview-header {
  padding: 8px;
  color: white;
}

.default-layout .preview-main {
  padding: 8px;
}

/* Common elements */
.preview-name {
  font-size: 9px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview-title {
  font-size: 8px;
  color: #666;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview-lines {
  margin-top: 8px;
}

.preview-line {
  height: 4px;
  background-color: #eee;
  margin-bottom: 4px;
  border-radius: 2px;
}

.preview-template-name {
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 6px 0;
  background-color: #f5f5f5;
  border-top: 1px solid #eee;
}

.preview-last-updated {
  font-size: 8px;
  color: #999;
  text-align: center;
  padding-bottom: 4px;
  background-color: #f5f5f5;
}

/* Loading state */
.preview-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}

.loading-spinner {
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Side header layout */
.side-header-layout {
  display: flex;
  flex-direction: column;
}

.side-header-layout .preview-header {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 35%;
  position: relative;
}

.side-header-layout .preview-header::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 30%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

/* Minimal layout */
.minimal-layout .preview-main {
  padding: 12px;
}

.minimal-layout .preview-name {
  font-size: 10px;
  margin-bottom: 8px;
}

/* Classic layout */
.classic-layout .preview-header {
  text-align: center;
  padding: 12px 8px;
}

/* Academic layout */
.academic-layout .preview-header {
  padding: 10px;
  border-bottom: 2px solid #555;
}

/* Modern layout */
.modern-layout .preview-header {
  padding: 12px 8px;
}

.modern-layout .preview-title {
  margin-top: 8px;
  padding-bottom: 6px;
  border-bottom: 1px solid #eee;
}

.cv-preview-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}

.cv-preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: transform 0.3s ease;
}

.cv-preview-thumbnail:hover .cv-preview-image {
  transform: scale(1.04);
} 